import React from "react";
import {Route, Switch} from "react-router-dom";
import {
    UserActivation,
} from "../modules/Auth/Pages";
import Commission from "../modules/Landing/pages/commission";
import Faq from "../modules/Faq/pages/Faq";
import LandingMain from "../modules/Landing/pages/index";
import ContactsPage from "../modules/Contacts/pages/ContactsPage";
import {APP_ROUTES} from "./constants/constantsUrl";
import Terms from "../modules/Landing/pages/Terms";
import PostbackOptions from "../modules/Landing/pages/PostbackOptions";
import Testimonials from "../modules/Testimonials/pages/Testimonials";
import NewsList from "../modules/News/pages/NewsList";
import {NewsContent} from "../modules/News/pages/NewsContent";
import Page404 from "../pages/error/pages/Page404";
import {
    Revshare,
    SubAffiliate,
    CPA,
} from "../modules/Landing/pages/commissions";

export default (
    <Switch>
        <Route path="/registration-activate" component={UserActivation}/>
        <Route path="/commission/revshare" component={Revshare}/>
        <Route path="/commission/cpa" component={CPA}/>
        <Route path="/commission/subaffiliate" component={SubAffiliate}/>
        <Route path="/commission" component={Commission}/>
        <Route path="/contacts" component={ContactsPage}/>
        <Route path={APP_ROUTES.terms} component={Terms}/>
        <Route path={APP_ROUTES.postback_options} component={PostbackOptions}/>
        <Route path={APP_ROUTES.testimonials} component={Testimonials} exact/>
        <Route path={APP_ROUTES.news.list} component={NewsList}/>
        <Route path={APP_ROUTES.news.show} component={NewsContent}/>
        <Route exact path="/" component={LandingMain}/>
        <Route exact path={APP_ROUTES.faq.index} component={Faq}/>
        <Route component={Page404}/>
    </Switch>
);
